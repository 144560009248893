import { ButtonGroup, Button, Typography, Tooltip, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useContext, useMemo } from "react";
import FunctionsIcon from '@mui/icons-material/Functions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrailer, faAnchor, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { blueGrey, red } from '@mui/material/colors';
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { sortObjDesc } from "utils";
import { useCallback } from "react";
import { initSummaryFilter } from "constants/dailyJobConstant";
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import { NoTransferRounded, PersonOffRounded } from "@mui/icons-material";
import PopoverBarebone from "components/PopoverBarebone";
import dayjs from "dayjs";
const BoxWrapper = ({ sx, boxTitle, boxIcon, children }) => {
  return (
    <Box sx={{ flexBasis: "content", minWidth: 300, flexShrink: 1, overflow: "hidden", pt: 1, ...sx }}>
      <BoxFR sx={{ border: "1px solid lightgrey", borderRadius: 1.5 }} position="relative" p={0.5} minHeight={45}>
        <Typography variant="overline" position="absolute" top={-16} bgcolor="white" px={1} sx={{ height: 20 }}>{boxTitle}</Typography>
        <Box mx={1}>
          <FontAwesomeIcon size="lg" icon={boxIcon} color={blueGrey[500]} />
        </Box>
        <BoxFR sx={{ overflowY: "none", overflowX: "auto" }}>
          {children}
        </BoxFR>
      </BoxFR>
    </Box>
  )
}
const DataItem = ({ title, header, data, color, selected, onClick }) => {
  return (
    <Tooltip title={title} arrow>
      <ButtonGroup >
        <Button size="small" variant="contained" color={color ? color : selected ? "secondary" : "primary"} onClick={onClick}>
          {header}
        </Button>
        <Button size="small" variant="outlined" color={color ? color : selected ? "secondary" : "primary"} onClick={onClick}>
          {data}
        </Button>
      </ButtonGroup>
    </Tooltip>
  )
}

// const HighlightButton = ({ name, label, selected, setSelected }) => (

//   <Button
//     variant={selected === name ? "contained" : "outlined"}
//     color="primary"
//     sx={{ p: 1 }} onClick={() => setSelected(o => ({ ...o, selectedHighlight: name }))}>
//     {label}
//   </Button>
// )

const calculateCustomer = (dataTable) => {
  const sumCus = {}
  for (const row of dataTable) {
    if (sumCus[row.CusId]) {
      sumCus[row.CusId].Qty++
    } else {
      sumCus[row.CusId] = {
        CusId: row.CusId,
        Name: row.CusSNm,
        Abbr: row.CusAbbr,
        Qty: 1
      }
    }
  }
  const cusArr = []
  for (const key in sumCus) {
    cusArr.push(sumCus[key])
  }
  const sortedCus = sortObjDesc(cusArr, "Qty")
  return sortedCus
}
const calculateJob = (dataTable) => {
  const sumJob = {}
  for (const row of dataTable) {
    if (sumJob[row.JobId]) {
      sumJob[row.JobId].Qty++
    } else {
      sumJob[row.JobId] = {
        JobId: row.JobId,
        Name: row.JobSNm,
        Abbr: row.JobAbbr,
        Qty: 1
      }
    }
  }
  const cusArr = []
  for (const key in sumJob) {
    cusArr.push(sumJob[key])
  }
  const sortedCus = sortObjDesc(cusArr, "Qty")
  return sortedCus
}

const initDisplayModule = {
  selection: true,
  sumRow: true,
  shpmType: true,
  trailerType: true,
  customer: true,
  job: false,
  drvTukAttn: true,
}

const SummaryBar = ({ dataTable, summaryFilter, setSummaryFilter, selectionModel, drvTukAttn
  , displayModule = initDisplayModule, initFilter = initSummaryFilter }) => {
  const { msData } = useContext(GlobalStateContext)
  const customerSummary = useMemo(() => calculateCustomer(dataTable), [dataTable])
  const jobSummary = useMemo(() => calculateJob(dataTable), [dataTable])
  const handleItemClick = useCallback((name, value) => {
    setSummaryFilter(o => {
      const newValue = o[name] !== null && o[name] === value ? null : value
      return {
        ...o,
        [name]: newValue
      }
    })
  }, [setSummaryFilter])

  // console.log("summaryFilter::", summaryFilter)
  return (
    <BoxFR sx={{ gap: 1 }} pt={1} overflow="auto" width="100%">
      {displayModule.selection && <DataItem title="งานที่เลือก" header={<FactCheckRoundedIcon />} data={selectionModel.length} />}

      {displayModule.sumRow &&
        <DataItem title="รวมงานทั้งหมด" header={<FunctionsIcon />} data={dataTable.length}
          onClick={() => setSummaryFilter(initFilter)} />
      }
      {displayModule.selection &&
        <BoxFR>
          <PopoverBarebone component={<DataItem title="พนักงานลา" header={<PersonOffRounded />}
            data={drvTukAttn?.drvAttns?.length || 0}
            color={drvTukAttn?.drvAttns?.length > 0 ? "error" : "primary"} />}>
            <Paper sx={{ p: 1 }} >
              <BoxFC sx={{gap:1}} p={1} border={`1px solid ${red[500]}`} borderRadius={2} bgcolor={red[50]}>
                {drvTukAttn?.drvAttns?.map((item, index) => (
                  <BoxFR key={index}>
                    <Typography width={150}>{item.NName}</Typography>
                    <Typography width={100}>{dayjs(item.DrvAttnDte).format("DD/MM/YYYY")}</Typography>
                    <Typography >{item.DrvAttnStsNm}{item.Dscp ? `(${item.Dscp})` : ""}</Typography>
                  </BoxFR>
                ))}
              </BoxFC>
            </Paper>
          </PopoverBarebone>

          <PopoverBarebone component={<DataItem title="รถไม่พร้อม" header={<NoTransferRounded />} data={drvTukAttn?.tukAttns?.length || 0}
            color={drvTukAttn?.tukAttns?.length > 0 ? "error" : "primary"} />}>
            <Paper sx={{ p: 1 }} >
              <BoxFC sx={{gap:1}} p={1} border={`1px solid ${red[500]}`} borderRadius={2} bgcolor={red[50]}>
                {drvTukAttn?.tukAttns?.map((item, index) => (
                  <BoxFR key={index}>
                    <Typography width={150}>{item.Code}</Typography>
                    <Typography width={100}>{dayjs(item.TukAttnDte).format("DD/MM/YYYY")}</Typography>
                    <Typography >{item.TukAttnStsNm}{item.Dscp ? `(${item.Dscp})` : ""}</Typography>
                  </BoxFR>
                ))}
              </BoxFC>
            </Paper>
          </PopoverBarebone>


        </BoxFR>
      }
      {displayModule.shpmType &&
        <BoxWrapper boxTitle="ประเภทงาน" boxIcon={faAnchor}>
          {msData.shipmentTypes.map(item => (
            <DataItem key={item.ShpmTypId} title={item.Name} header={item.Abbr}
              data={dataTable.filter(row => row.ShpmTypId === item.ShpmTypId).length}
              selected={item.ShpmTypId === summaryFilter.ShpmTypId}
              onClick={() => handleItemClick("ShpmTypId", item.ShpmTypId)} />
          ))}
        </BoxWrapper>
      }

      {displayModule.trailerType &&
        <BoxWrapper boxTitle="ประเภทหาง" boxIcon={faTrailer}>
          {msData.trailerTypes.map(item => (
            <DataItem key={item.TlrTypId} title={item.Name} header={item.Abbr}
              data={dataTable.filter(row => row.TlrTypId === item.TlrTypId).length}
              selected={item.TlrTypId === summaryFilter.TlrTypId}
              onClick={() => handleItemClick("TlrTypId", item.TlrTypId)} />
          ))}
        </BoxWrapper>
      }

      {displayModule.customer &&
        // <Box sx={{ flexBasis: 400, flexShrink: 1, flexGrow: 1, overflowX: "auto", overflowY: "none", pt: 1, mt: -1 }}>
        <BoxWrapper boxTitle="ลูกค้า" boxIcon={faUserCircle} sx={{ flexBasis: 400, flexGrow: 1 }}>
          {customerSummary.map(item => (
            <DataItem key={item.CusId} title={item.Name} header={item.Abbr}
              data={item.Qty}
              selected={item.CusId === summaryFilter.CusId}
              onClick={() => handleItemClick("CusId", item.CusId)} />
          ))}
        </BoxWrapper>
        // </Box>
      }

      {
        displayModule.job &&
        <BoxWrapper boxTitle="งาน" boxIcon={faUserCircle} >
          {jobSummary.map(item => (
            <DataItem key={item.JobId} title={item.Name} header={item.Abbr}
              data={item.Qty}
              selected={item.JobId === summaryFilter.JobId}
              onClick={() => handleItemClick("JobId", item.JobId)} />
          ))}
        </BoxWrapper>
      }

    </BoxFR>
  )
}

export default React.memo(SummaryBar)